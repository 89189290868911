<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import OrdersList from '@/components/MenuSidebar/OrdersComponent.vue';
import Tabs from "@/components/Tabs.vue";
import { useProductStore } from "@/store/product";
import { toggleLoading } from "@/event-bus";
import { useUserStore } from '@/store/user';
import router from '@/router';

const activeTab = ref()
const productStore = useProductStore();
const userStore = useUserStore();
const list_header = computed(()=>[
  {name:'Pending',slug:'pending', data: orders_.value.filter((order)=>order?.status === 'pending') },
  {name:'Completed',slug:'completed', data: orders_.value.filter((order)=>order?.status === 'successful') },
  {name: 'Failed', slug: 'failed', data: orders_.value.filter((order)=>order?.status === 'failed') },
])
const activeData = computed(()=>list_header.value.find((tab)=>tab.slug === activeTab.value))
const orders_ = ref<{
  status: string;
  item: {
    id: string;
    name: string;
    price: number;
    image: string;
    new_price: number;
    size: string[];
  }
  quantity: number;
}[]>([])

onMounted(async () => {
    toggleLoading(true);

    if (userStore.getUser.token){
        const orders = await productStore.fetchAllOrders();
        const detailsPromises = orders.map(order => productStore.fetchOrderDetails({ orderId: order.id }));
        const details = await Promise.all(detailsPromises);

        orders_.value = details.reduce((acc, orderDetails, i) => {
            const status = orders[i].status;
            const orderWithDetails = orderDetails.map(detail => ({ status, ...detail }));
            return [...acc, ...orderWithDetails];
        }, []);

        toggleLoading(false);
    } else {
        toggleLoading(false);
        router.push('/signin');
    }
})
</script>

<template>
    <div class="main-header">
        <div class="main-btn">
            <div class="btn-container">
                <div class="flex justify-center px-10">
                    <Tabs
                        :tab_list="list_header"
                        :value="activeTab"
                        query_name="mixed"
                        @model-value="($event) => (activeTab = $event)"
                        class="inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px]
                        [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw]
                        overflow-x-auto hide-scrollbar">
                        <template v-slot:tab="{ tab, index: i, is_active }">
                            <div
                            :class="[
                                is_active
                                ? 'bg-primary text-white'
                                : 'bg-transparent hover:text-primary',
                            ]"
                            class="p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"
                            >
                            <span class="flex flex-col items-center">
                                <span>{{ tab?.name }}</span>
                            </span>
                            </div>
                        </template>
                    </Tabs>
                </div>
            </div>
            <!-- <div class="filter-text-container">
                <button class="filter-text-button">
                    <span class="filter-button-text font-class">Filters</span>
                    <box-icon name='filter'></box-icon>
                </button>
            </div> -->
        </div>
        <template v-if="activeData?.data.length">
          <OrdersList v-for="(order,n) in activeData?.data" :key="n"
              :product_name="order?.item?.name"
              :image="order?.item?.image"
              :price="order?.item?.new_price ?? order?.item?.price"
              :size="order?.item?.size"
              :order_number="order?.quantity"
              :can_delete="false || activeTab === list_header[2].slug"
              :is_history="false || activeTab === list_header[1].slug"
          />
        </template>
              <div
      v-if="activeData?.data.length === 0 && !userStore.getAuthHeader"
      class="h-96 bg-gray-50 flex items-center justify-center
      flex-col text-2xl font-medium text-gray-300">
      <span>
        You have to be logged in to view orders
      </span>
      <router-link
        v-if="!userStore.getUser.token"
        to="/signin"
        class="text-gray-500">Sign in</router-link>
    </div>
        <div v-if="activeData?.data.length === 0" class="flex justify-center items-center h-[30vh]">
          <span class="text-2xl font-medium text-gray-400">No {{activeData?.name}} orders</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main-header {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    margin-right: 80px;
    height: 100%;
    width: 100%;
    .main-btn {
        display: flex;
        width: 100%;
        height: auto;
        margin-top: 2.5rem;
        margin-bottom: 5.5rem;
        .btn-container {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            height: 100%;
            .main-btn-container {
            display: flex;
            justify-self: center;
            border-radius: 40px;
            border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
            height: 100%;
            width: fit-content;
            gap: 60px;
            .router-link {
                text-decoration: none;
                color: inherit;
                height: 100%;
                width: 100%;
            }
            .selected {
                background: #4385F5;
                color: #FFFFFF;
                opacity: 1;
            }
            button {
                background: transparent;
                font-family: 'DM Sans', sans-serif;
                border-radius: 43px;
                display: flex;
                margin-right: 1.5rem;
                height: 45px;
                padding: 10px 16px 10px 16px;
                font-size: 16px;
                line-height: 20.83px;
                justify-content: center;
                align-items: center;
                span {
                    font-weight: 300;
                    opacity: .7;
                }
            }
            button:nth-last-child(1) {
                margin-right: 0;
            }
        }
        }
        .filter-text-container {
            display: flex;
            width: 100px;
            justify-self: flex-end;
            grid-row: 1 / 1;
            button {
                display: flex;
                outline: 0;
                border: 0;
                background: transparent;
                height: 48px;
                font-size: 16px;
                font-weight: 500;
                line-height: 20.83px;
                text-align: center;
                margin-left: 0.5rem;
                align-items: center;
                span {
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
</style>