import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/images//icons/emptyList.svg'


const _hoisted_1 = { class: "intro mb-4" }
const _hoisted_2 = { class: "second-header-nav flex w-full justify-between" }
const _hoisted_3 = { class: "header-nav-main-text-container" }
const _hoisted_4 = { class: "main-text font-class" }
const _hoisted_5 = { class: "header-nav-pages-link" }
const _hoisted_6 = { class: "home hover:text-blue-400" }
const _hoisted_7 = { class: "arrow-container" }
const _hoisted_8 = { class: "product-gender-container hover:text-blue-400" }
const _hoisted_9 = { class: "arrow-container" }
const _hoisted_10 = { class: "product-page-container hover:text-blue-400" }
const _hoisted_11 = { class: "font-class" }
const _hoisted_12 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_13 = { class: "flex flex-col items-center" }
const _hoisted_14 = { class: "flex justify-center px-10" }
const _hoisted_15 = { class: "flex flex-col items-center" }
const _hoisted_16 = {
  key: 0,
  class: "container"
}
const _hoisted_17 = { id: "image" }
const _hoisted_18 = {
  key: 1,
  class: "h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { class: "flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300 pb-20" }
const _hoisted_21 = { class: "w-full flex justify-center pb-10" }

import { ref, computed, onMounted, watch, ComputedRef } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useProductStore } from "@/store/product";
import Pagination from "@/components/Pagination.vue";
import Tabs from "@/components/Tabs.vue";
import ItemCard from "@/components/ItemCard.vue";
import Spinner from "@/components/Spinner.vue";
// import usePagination from '@/composables/usePagination';
import axios from "axios";
import { fetchCategories } from "@/utils";
import { useQuery } from "@tanstack/vue-query";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryDetails',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const product = useProductStore();
const fetching = ref(false);

function parseUrlParameters(url: string | URL) {
  const parsedURL = new URL(url);
  const subCategoryFromL = parsedURL.searchParams.get("sub_category");
  const depSection = parsedURL.searchParams.get("dep_section");

  return { subCategoryFromL, depSection };
}

const { data: categories, refetch } = useQuery({
  queryKey: ["categories"],
  queryFn: fetchCategories,
});

onMounted(async () => {
  fetching.value = true;
  if (categories?.value?.length === 0) {
    await refetch();
  }

  // running this for when user visits the page
  // reason: viewedCategory.value could have been set from a different page
  // so the watcher doesn't run
  if ((subCategory.value || [])?.length === 0) initSubCategoriesAndSections();
});

const viewedCategory = computed(() => {
  const category = route.params.category as string;
  return categories?.value?.find((cat: { name: string }) =>
    cat.name.toLocaleLowerCase().includes(category)
  );
});

const subCategory = computed(() =>
  product.getSubcategoryById(viewedCategory.value?.id || 9)
);

const sectionDependentOnSubCat = computed(() => {
  if (!viewedCategory.value?.id && !activeSubCategory.value) return [];
  return product.getSection({
    categoryId: Number(viewedCategory.value?.id),
    subCategoryId: Number(activeSubCategory.value),
  });
});

const initSubCategoriesAndSections = () => {
  Promise.all([product.fetchSubCategories(), product.fetchSections()]);
};

const activeSubCategory = ref();
const activeDependentSection = ref();

watch(
  [() => router.currentRoute.value.query, activeSubCategory],
  (qs) => {
    const [q] = qs;
    console.log({qs, q});
    if (q.dep_section || q.sub_category) return;
    initSubCategoriesAndSections();
  },
  { deep: true }
);

const renderedSubCatOnTabList: ComputedRef<{ name: string; slug: string }[]> =
  computed(() => {
    return subCategory.value?.map((subCat: { name: any; id: any }) => {
      return {
        name: subCat.name,
        slug: String(subCat.id),
      };
    });
  });

const renderedSectionOnTabList: ComputedRef<{ name: string; slug: string }[]> =
  computed(() => {
    return sectionDependentOnSubCat.value?.map((section) => {
      return {
        name: section.name,
        slug: String(section.id),
      };
    });
  });

let prevVals: [any, any, any] | null = null;

watch(
  [viewedCategory, activeSubCategory, activeDependentSection],
  async (val) => {
    const [catVal, subCatVal, sectionVal] = val;
    if (prevVals?.every((prev, index) => prev === val[index])) return;
    prevVals = val;

    if (!catVal?.id || !sectionVal) return;

    fetching.value = true;

    try {
      await product.fetchItemsInCategorySubcategoryAndSection({
        categoryId: Number(catVal?.id),
        subCategoryId: Number(subCatVal),
        sectionId: Number(sectionVal),
      });
    } finally {
      fetching.value = false;
    }
  },
  { deep: true }
);

const fetchingMore = ref(false);
const fetchMore = (link: string) => {
  fetchingMore.value = true;
  axios
    .get(link)
    .then(({ data }) => {
      product.setItems(data);
    })
    .finally(() => {
      fetchingMore.value = false;
    });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(viewedCategory.value ? viewedCategory.value.name : "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              class: "router-link",
              to: "/"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", { class: "home-link-text font-class" }, "Home", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_box_icon, { name: "chevron-right" })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_link, {
              class: "router-link",
              to: "/categories"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("span", { class: "font-class" }, "Categories", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_box_icon, { name: "chevron-right" })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_router_link, {
              class: "router-link",
              to: "/categories/{{viewedCategory && viewedCategory.name}}"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_11, _toDisplayString(viewedCategory.value ? viewedCategory.value.name : "-"), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      (renderedSubCatOnTabList.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(Tabs, {
              tab_list: renderedSubCatOnTabList.value,
              value: activeSubCategory.value,
              query_name: "sub_category",
              onModelValue: _cache[0] || (_cache[0] = ($event) => {
            activeSubCategory.value = $event
            activeDependentSection.value = renderedSectionOnTabList.value[0]
          }),
              class: "inline-flex min-w-[40vw] md:min-w-0 gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] w-[80%] md:w-full overflow-x-scroll scroll-div"
            }, {
              tab: _withCtx(({ tab, index: i, is_active }) => [
                _createElementVNode("div", {
                  class: _normalizeClass([[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ], "p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"])
                }, [
                  _createElementVNode("span", _hoisted_13, [
                    _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
                  ])
                ], 2)
              ]),
              _: 1
            }, 8, ["tab_list", "value"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(Tabs, {
          tab_list: renderedSectionOnTabList.value,
          value: activeDependentSection.value,
          query_name: "dep_section",
          onModelValue: _cache[1] || (_cache[1] = ($event) => (activeDependentSection.value = $event)),
          class: "inline-flex min-w-[40vw] md:min-w-0 gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] w-[80%] md:w-full overflow-x-scroll scroll-div"
        }, {
          tab: _withCtx(({ tab, index: i, is_active }) => [
            _createElementVNode("div", {
              class: _normalizeClass([[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ], "p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"])
            }, [
              _createElementVNode("span", _hoisted_15, [
                _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["tab_list", "value"])
      ])
    ]),
    (renderedSubCatOnTabList.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(product).items?.results || [], (item) => {
              return (_openBlock(), _createBlock(ItemCard, {
                key: item.id,
                id: item.id,
                favorite_id: item.favorite_id,
                name: item.name,
                price: item.price,
                new_price: item.new_price,
                image: item.image,
                slug: item.slug,
                is_favourite: item.is_favourite
              }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "slug", "is_favourite"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (fetching.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _cache[4] || (_cache[4] = _createElementVNode("span", null, " Refreshing item list... ", -1)),
          _createVNode(Spinner, { class: "w-8 text-gray-500" })
        ]))
      : _createCommentVNode("", true),
    (!_unref(product).items?.results?.length && !renderedSubCatOnTabList.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              src: _imports_0,
              class: "w-1/3",
              alt: "No product match this category"
            }, null, -1)),
            _createElementVNode("span", null, " There are no products in - " + _toDisplayString(viewedCategory.value?.name), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(Pagination, {
        data: _unref(product).items!,
        onFetchMore: fetchMore,
        loading: fetchingMore.value,
        class: "mx-auto"
      }, null, 8, ["data", "loading"])
    ])
  ]))
}
}

})