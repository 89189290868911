<template>
  <div id="register">
    <div v-if="status === 'nolink'" id="details">
      <AuthHeader />
      <h1>Sign Up</h1>
      <p>
        Already have an account?
        <span>
          <router-link class="router-link" to="/signin">
            Sign in to your Account
          </router-link>
        </span>
      </p>
      <form action="" method="post">
        <div class="input">
          <input
            v-model="username"
            type="text"
            ref="name"
            placeholder="Enter your username"
            id="form-name"
          />
          <span class="error">{{ formatErrorMessage(errors.username) }}</span>
        </div>
        <div class="input">
          <input
            v-model="email"
            type="email"
            ref="email"
            placeholder="Enter your email"
            id="form-email"
          />
          <span class="error">{{ formatErrorMessage(errors.email) }}</span>
        </div>
        <div class="input">
          <input
            v-model="password"
            type="password"
            ref="password"
            placeholder="Enter your password"
            id="form-password"
          />
          <span class="error">{{ formatErrorMessage(errors.password) }}</span>
        </div>
        <div class="input">
          <input
            v-model="re_password"
            type="password"
            ref="re-password"
            placeholder="Re-enter your password"
            id="re-password"
          />
          <span class="error">{{
            formatErrorMessage(errors.non_field_errors)
          }}</span>
        </div>
        <div class="input">
          <input
            @click="registerUser()"
            type="button"
            name="submit"
            :value="buttonValue"
            id="form-button"
            :disabled="buttonDisabled"
            :style="{ opacity: buttonDisabled ? '0.3' : '1' }"
          />
          <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
        </div>
      </form>
      <!-- <div id="socials">
        <div class="social-icon" style="width: 30px; height: 30px">
          <img src="../assets/images/icons/google.png" alt="" />
        </div>
        <div class="social-icon" style="width: 30px; height: 30px">
          <img src="../assets/images/icons/twitter.png" alt="" />
        </div>
        <div class="social-icon" style="width: 30px; height: 30px">
          <img src="../assets/images/icons/instagram.png" alt="" />
        </div>
      </div> -->
    </div>
    <div v-if="status === 'linksent'" id="details">
      <AuthHeader />
      <form action="" method="post">
        <h3 class="subbigtext">Verification Link Sent!</h3>
        <p>Check your email</p>
        <!--
                    <div class="input">
                    <input @click="signIn()" type="button" name="submit" :value="buttonValue" id="form-button" :disabled="buttonDisabled" :style="{ opacity: buttonDisabled ? '0.3' : '1' }" >
                    <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
                </div>
                -->
      </form>
    </div>

    <div id="photo-div">
      <img
        src="../assets/images/signup1.png"
        alt="A black shirt with diagonal tiles on a lady"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#register {
  padding: 51px 80px 44px;
  background: var(--white, #fff);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  line-height: normal;
  width: 100vw;
  // height: 150vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  overflow-y: auto;
  #details {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 48px;
      color: var(--black-100, #0f172a);
      font-weight: 500;
      margin-bottom: 7px;
      margin-top: 2rem;
    }
    p {
      color: var(--black-50, rgba(15, 23, 42, 0.5));
      font-size: 14px;
      font-weight: 500;
      span {
        a.router-link {
          text-decoration: none;
          color: var(--button-default, #4385f5);
        }
      }
    }
    form {
      flex-grow: 1;
      margin-top: 47px;
      margin-bottom: 75px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 366px;
      font-family: "DM Sans", sans-serif;
      color: var(--black-50, rgba(15, 23, 42, 0.5));
      h3 {
        color: var(--black-100, #0f172a);
        font-family: DM Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .input {
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        align-items: center;
        span {
          font-size: 11px;
          color: red;
        }
      }
      input {
        width: 100%;
        border-radius: 30px;
        padding: 24px;
        border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
      }
      #form-button {
        background: var(--button-default, #4385f5);
        border: none;
        border-radius: 30px;
        color: #fff;
      }
    }
    // #socials {
    //   display: flex;
    //   gap: 61px;
    //   margin-bottom: 100px;
    // }
  }
  #photo-div {
    width: 50%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      border-radius: 15px;
    }
  }
}

@media only screen and (max-width: 940px) {
  #register {
    padding: 54px 24px 31px;
    flex-direction: column;
    height: fit-content;
    height: auto;
    #details {
      width: 100%;
      align-items: flex-start;
      height: fit-content;
      h1 {
        margin-top: 47px;
        margin-bottom: 28px;
        font-size: 32px;
      }
      p {
        font-size: 14px;
      }
      form {
        gap: 19px;
        width: 100%;
        h3 {
          font-size: 24px;
        }
      }
      // #socials {
      //   width: 100%;
      //   justify-content: center;
      //   margin-bottom: 0px;
      // }
    }
    #photo-div {
      display: none;
    }
  }
}
</style>

<script lang="ts">
import AuthHeader from "@/components/AuthHeader.vue";
import axios from "axios";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineComponent({
  name: "SignUp",
  components: {
    AuthHeader,
  },
  data() {
    return {
      status: "nolink",
      buttonValue: "Sign Up",
      buttonDisabled: false,
      username: "",
      email: "",
      password: "",
      re_password: "",
      errors: {
        username: this.username,
        email: this.email,
        password: this.password,
        re_password: this.re_password,
        non_field_errors: "",
        detail: "",
      },
    };
  },
  methods: {
    formatErrorMessage(messages: Function | string) {
      if (Array.isArray(messages)) {
        return messages.join(" ");
      }
      return messages;
    },
    async registerUser() {
      this.buttonValue = "Signing you up...";
      this.buttonDisabled = true;

      const userData = {
        username: this.username.toLowerCase(),
        email: this.email.toLowerCase(),
        password: this.password,
        re_password: this.re_password,
      };
      const userDetails = {
        username: this.username,
        email: this.email,
        password: this.password,
      };

      try {
        const response = await axios.post("/auth/users/", userData);
        console.log("Signup successful:", response.data);
        this.status = "linksent";

        setTimeout(() => {
          this.$router.push({ name: "signin" });
        }, 5000);
      } catch (error) {
        this.buttonValue = "Sign Up";
        this.buttonDisabled = false;

        if (axios.isAxiosError(error)) {
          // alert(error.message);
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 401) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 403) {
            this.errors = error.response.data;
          }
        } else {
          console.error("Non-Axios error:", error);
          this.errors.detail = "An error occured. Please try again";
        }
        console.error("Registration failed:", error);
        toast.error(this.errors.detail || "An error occured. Please try");
      }
    },
  },
});
</script>
