import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "textbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_name = _resolveComponent("name")!
  const _component_username = _resolveComponent("username")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["profile-details", { border: _ctx.settings === true}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_name, {
        first_name: _ctx.firstName,
        last_name: _ctx.lastName
      }, null, 8, ["first_name", "last_name"]),
      _createVNode(_component_username, { username: _ctx.username }, null, 8, ["username"])
    ])
  ], 2))
}