<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import name from './ProfileName.vue'
import userphoto from './ProfilePhoto.vue'
import username from './ProfileUsername.vue'
import { toggleLoading } from '@/event-bus';

export default defineComponent({
    name: 'User',
    components: {
        name,
        userphoto,
        username
    },
    data() {
        return {
            settings: true,
            username: 'unknown',
            firstName: 'Not',
            lastName: 'logged in',
            errors: {
                username: this.username,
                firstName: this.firstName,
                lastName: this.lastName,
                detail: '',
            },
        }
    },
    methods: {
        formatErrorMessage(messages: string | string[]) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        getRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'MyProfile') {
                this.settings = false
            } else if (routerChoice == 'Settings') {
                this.settings = true
            }
        },
        async fetchUsername() {
            toggleLoading(true);
            const token = localStorage.getItem('AuthToken');

            function handleAxiosError(error, errors) {
                console.log({ error, errors, wahala: "true"});

                if (axios.isAxiosError(error)) {
                    if (error.response && error.response.status === 400) {
                        errors.detail = error.response.data.detail;
                    } else if (error.response && error.response.status === 401) {
                        errors.detail = error.response.data.detail;
                    } else if (error.response && error.response.status === 403) {
                        errors.detail = error.response.data.detail;
                    } else if (error.response && error.response.status === 404) {
                        errors.detail = error.response.data.detail;
                    }
                } else {
                    errors.detail = 'An error occured. Please try again';
                    console.error('Non-Axios error:', error);
                }
            };

            try {
                const response = await axios.get('/auth/users/me/', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });

                this.username = response.data.username;
                this.firstName = 'Name';
                this.lastName = 'not set';

                const userId = response.data.id;
                try {
                    const response = await axios.get(`/api/profile/`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                        },
                    });

                    toggleLoading(false);
                    this.firstName = response.data[0].first_name;
                    this.lastName = response.data[0].last_name;

                    if (!this.firstName) {
                        this.firstName = 'No first name';
                    }

                    if (!this.lastName) {
                        this.lastName = 'No last name';
                    }
                } catch (error) {
                    toggleLoading(false);
                    console.log('Error fetching user details:', error);
                    handleAxiosError(error, this.errors);
                }
            } catch (error) {
                toggleLoading(false);
                console.log('Error fetching username:', error);
                handleAxiosError(error, this.errors);
            }
        },
    },
    created() {
        this.getRoute();
        this.fetchUsername();
    },
    mounted() {
        this.fetchUsername();
    },
    watch: {
        $route(to, from) {
            this.getRoute();
        }
    },
})
</script>

<template>
    <div class="profile-details" :class="{ border: settings === true}">
        <!--
            <div class="profile-photo">
            <userphoto />
        </div>
        -->
        <div class="textbox">
            <name
            :first_name="firstName"
            :last_name="lastName" />
            <username
            :username="username" />
            <!-- <div class="error">
                <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
            </div> -->
        </div>
        <!--<div class="edit-button" v-if="settings === true">
            <button class="edit" style="cursor: pointer;">
                <span class="edit-text">Save</span>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3603 0.195726C13.0996 -0.065242 12.6769 -0.0652421 12.4162 0.195726L11.8338 0.778677C11.0894 0.422698 10.1707 0.553319 9.55407 1.17054L2.47309 8.25841L6.24961 12.0386L13.3306 4.95074C13.9472 4.33352 14.0777 3.41396 13.7221 2.66878L14.3045 2.08583C14.5652 1.82486 14.5652 1.40174 14.3045 1.14078L13.3603 0.195726ZM10.511 5.88297L6.24961 10.1485L4.36135 8.25841L8.62275 3.99287L10.511 5.88297ZM11.7249 4.6679L12.3865 4.00569C12.6472 3.74472 12.6472 3.32161 12.3865 3.06064L11.4423 2.11559C11.1816 1.85462 10.7589 1.85462 10.4982 2.11559L9.83663 2.7778L11.7249 4.6679Z" fill="#0F172A" fill-opacity="0.5"/>
                    <path d="M0.5 14L1.91646 8.80197L5.69272 12.5824L0.5 14Z" fill="#0F172A"/>
                </svg>
            </button>
        </div>-->
    </div>
</template>

<style lang="scss" scoped>
.profile-details {
    display: flex;
    gap: 22px;
    padding: 26px 47px;
    width: 100%;
    align-items: center;
    .profile-photo {
        width: 100px;
        height: 100px;
    }
    .textbox {
        display: flex;
        flex-direction: column;
        gap: 9px;
        justify-content: center;
        flex-grow: 1;
        .error {
            font-size: 11px;
            color: red;
        }
    }
    .edit-button {
        opacity: .5;
        .edit {
            padding: 10px;
            background-color: transparent;
            height: fit-content;
            width: fit-content;
            display: flex;
            gap: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            border: 1px solid var(--black-50, rgba(15, 23, 42, 1));
            span {
                color: var(--black-50, rgba(15, 23, 42, 1));
                font-family: DM Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
    }
    .edit-button:hover {
            opacity: 1;
    }
}

.border {
    border-radius: 24px;
    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
}

@media only screen and (max-width: 940px) {
    .profile-details {
        padding: 0px;
        border: none;
        .profile-photo {
            width: 4.375rem;
            height: 4.375rem;
            flex-shrink: 0;
        }
        .textbox {
            gap: 5px;
        }
        .edit-button {
            span {
                font-size: 1.125rem;
            }
        }
    }
}
</style>