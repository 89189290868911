<script lang="ts">
import { defineComponent } from "vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { toggleLoading } from "@/event-bus";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const data = ref({
  selectedImage: null,
});

export default defineComponent({
  name: "PersonalInfo",
  data() {
    return {
      Save: "Save",
      file: "",
      settings: true,
      firstName: "-",
      lastName: "-",
      emailAddress: "-",
      mobileNumber: "-",
      errors: {
        detail: "",
      },
      inputDisabled: true,
      fileContent: null,
      deleteAccountModal: false,
      pw: "",
      loggedInUser: JSON.parse(localStorage.getItem('user')  || "{}" ),
    };
  },
  props: {
    first_name: String,
    last_name: String,
    email: String,
    phone_number: String,
  },
  methods: {
    //        handleFileChange(event: Event) {
    //            this.selectedFile = event?.target?.files?.[0] || null;
    //        },
    formatErrorMessage(messages: string | string[]) {
      if (Array.isArray(messages)) {
        return messages.join(" "); // Join array elements with a space
      }
      return messages; // Return empty string if no error message
    },
    getRoute() {
      const route = useRoute();
      const routerChoice = route?.name;

      if (routerChoice == "MyProfile") {
        this.settings = false;
      } else if (routerChoice == "Settings") {
        this.settings = true;
      }
    },
    async getUserDetails() {
      toggleLoading(true);
      const token = localStorage.getItem("AuthToken");

      try {
        const responseEmail = await axios.get("/auth/users/me", {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
        this.emailAddress = responseEmail.data.email;
        const userId = responseEmail.data.id;
        //console.log(`${userId}`)

        try {
          // What shouldbe gotten from this API is mobile number and profile photo
          const response = await axios.get(`/api/profile/`, {
            //${userId}/
            headers: {
              Authorization: `JWT ${token}`,
            },
          });
          this.mobileNumber = response.data[0].phone_number;
          this.firstName = response.data[0].first_name;
          this.lastName = response.data[0].last_name;
          toggleLoading(false);
        } catch (error) {
          toggleLoading(false);
          if (axios.isAxiosError(error)) {
            if (error.response && error.response.status == 400) {
              this.errors = error.response.data;
            } else if (error.response && error.response.status === 401) {
              this.errors = error.response.data;
            } else if (error.response && error.response.status === 403) {
              this.errors = error.response.data;
            } else if (error.response && error.response.status === 404) {
              this.errors = error.response.data;
            }
          } else {
            (this.errors.detail = "An error occured. Please try again"),
              console.error("Name error o! Non-Axios error:", error);
          }
        }
      } catch (error) {
        toggleLoading(false);
        console.error("Error fetching email address:", error);
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 401) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 403) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 404) {
            this.errors = error.response.data;
          }
        } else {
          (this.errors.detail = "An error occured. Please try again"),
            console.error("Email error o! Non-Axios error:", error);
        }
      }
    },
    checkRoute() {
      const route = useRoute();
      const routerChoice = route?.name;

      if (routerChoice == "Settings") {
        this.inputDisabled = false;
      }
    },

    async saveUserDetails() {
      this.Save = "Saving";

      const token = localStorage.getItem("AuthToken");
      const userData = {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.mobileNumber,
      };
      //            let fileInput = this.$refs.file as HTMLInputElement;
      //            let image = fileInput.files;
      //            if (image && image[0]) {
      //                let reader = new FileReader
      //                reader.onload = (e) => {
      //                    this.fileContent = e.target.result
      //                }
      //                reader.readAsDataURL(image[0])
      //                this.$emit('fileInput', image[0])
      //            }

      const userForm = new FormData();
      userForm.append("first_name", String(this.firstName.trim()));
      userForm.append("last_name", String(this.lastName.trim()));
      userForm.append("phone_number", this.mobileNumber.trim() as string);
      userForm.append("image", this.file);
      //userForm.append('image', data.selectedImage);

      if (this.firstName === "Name" || this.lastName === "not set") {
        if (this.file === "") {
          try {
            const response = await axios.post(`/api/profile/`, userForm, {
              headers: {
                Authorization: `JWT ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            });
            this.Save = "Save";
            console.log("Profile update successful!:", response.data);
            this.getUserDetails;
          } catch (error) {
            this.Save = "Save";
            console.error("A Form failed:", error);
          }
        } else {
          try {
            const response = await axios.post(`/api/profile/`, userForm, {
              headers: {
                Authorization: `JWT ${token}`,
                "Content-Type": "multipart/form-data",
              },
            });
            console.log("Profile update successful!:", response.data);
            this.getUserDetails;
            this.Save = "Save";
          } catch (error) {
            this.Save = "Save";
            console.error("B Form failed:", error);
          }
        }
      } else {
        if (this.file === "") {
          try {
            //Just wanna get userID
            const responseEmail = await axios.get("/auth/users/me", {
              headers: {
                Authorization: `JWT ${token}`,
              },
            });
            const userId = responseEmail.data.id;
            //console.log(`${userId}`)

            try {
              const response = await axios.post(`/api/profile/`, userForm, {
                //${userId}/
                headers: {
                  Authorization: `JWT ${token}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              });
              this.Save = "Saved";
              console.log("Profile update successful!:", response.data);
              this.getUserDetails;
            } catch (error) {
              this.Save = "Error. Re-save";
              console.error("C Form failed:", error);
            }
          } catch (error) {
            this.Save = "Save";
            console.error("Failed:", error);
          }
        } else {
          try {
            //Just wanna get userID
            const responseEmail = await axios.get("/auth/users/me", {
              headers: {
                Authorization: `JWT ${token}`,
              },
            });
            const userId = responseEmail.data.id;
            //console.log(`${userId}`)

            try {
              const response = await axios.put(
                `/api/profile/${userId}/`,
                userForm,
                {
                  headers: {
                    Authorization: `JWT ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              this.Save = "Save";
              console.log("Profile update successful!:", response.data);
              this.getUserDetails;
            } catch (error) {
              this.Save = "Save";
              console.error("D Form failed:", error);
            }
          } catch (error) {
            this.Save = "Save";
            console.error("Failed:", error);
          }
        }
      }
    },

    async handleDeleteAccount() {
      const deleteAcct = async () => {
        const id = toast.loading("Deleting to favorites...", {
          position: toast.POSITION.TOP_RIGHT,
        });
        try {
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/auth/users/me/`,
            {
              body: JSON.stringify({ current_password: this.pw }),
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const res = await response.json();

          if (res) {
            toast.update(id, {
              render: "Done",
              type: "success",
              isLoading: false,
            });

            localStorage.removeItem("AuthToken");
            this.$router.push({ name: "Login" });
          }
        } catch (error) {
          toast.update(id, {
            render: "Error",
            type: "error",
            isLoading: false,
          });
        } finally {
          setTimeout(() => toast.remove(id), 1000);
        }
      };

      await deleteAcct();
    },
  },
  created() {
    this.getRoute();
    this.checkRoute();
    this.getUserDetails();
  },
  mounted() {},
  watch: {
    $route(to: { name: string }, from: any) {
      this.getRoute();
      if (to.name === "Settings") {
        this.inputDisabled = false;
      }
    },
  },
});
</script>

<template>
  <div class="info-details" :class="{ border: settings === true }">
    <div class="title-div">
      <div class="input">
        <span class="title">Personal Information</span>
        <!-- <span class="error">{{ formatErrorMessage(errors.detail) }}</span> -->
      </div>
      <div class="edit-button" v-if="settings === true">
        <button class="edit" style="cursor: pointer" @click="saveUserDetails()">
          <span class="edit-text">{{ Save }}</span>
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.3603 0.195726C13.0996 -0.065242 12.6769 -0.0652421 12.4162 0.195726L11.8338 0.778677C11.0894 0.422698 10.1707 0.553319 9.55407 1.17054L2.47309 8.25841L6.24961 12.0386L13.3306 4.95074C13.9472 4.33352 14.0777 3.41396 13.7221 2.66878L14.3045 2.08583C14.5652 1.82486 14.5652 1.40174 14.3045 1.14078L13.3603 0.195726ZM10.511 5.88297L6.24961 10.1485L4.36135 8.25841L8.62275 3.99287L10.511 5.88297ZM11.7249 4.6679L12.3865 4.00569C12.6472 3.74472 12.6472 3.32161 12.3865 3.06064L11.4423 2.11559C11.1816 1.85462 10.7589 1.85462 10.4982 2.11559L9.83663 2.7778L11.7249 4.6679Z"
              fill="#0F172A"
              fill-opacity="0.5" />
            <path
              d="M0.5 14L1.91646 8.80197L5.69272 12.5824L0.5 14Z"
              fill="#0F172A" />
          </svg>
        </button>
      </div>
    </div>
    <div class="">
      <form action="" class="content-div" method="">
        <div class="details">
          <div class="container">
            <p class="content-text">First name</p>
            <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="text" name="fname" value="{{ first_name }}">
                    -->
            <input
              :disabled="inputDisabled"
              v-model="firstName"
              :class="{ border: settings === true }"
              type="text"
              name="first-name"
              placeholder="First Name" />
          </div>
          <div class="container">
            <p class="content-text">Last name</p>
            <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="text" name="fname" value="{{ last_name }}">
                    -->
            <input
              :disabled="inputDisabled"
              v-model="lastName"
              :class="{ border: settings === true }"
              type="text"
              name="last-name"
              placeholder="Last name" />
          </div>
        </div>
        <div class="details">
          <div class="container">
            <p class="content-text">Email address</p>
            <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="email" name="email" value="{{ email }}">
                    -->
            <input
              :disabled="inputDisabled"
              v-model="emailAddress"
              :class="{ border: settings === true }"
              type="email"
              name="email"
              placeholder="Email Address" />
          </div>
          <div class="container">
            <p class="content-text">Mobile number</p>
            <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="number" name="number" value="{{ phone_number }}">
                    -->
            <input
              :disabled="inputDisabled"
              v-model="mobileNumber"
              :class="{ border: settings === true }"
              type="text"
              name="phone-number"
              placeholder="Mobile Number" />
          </div>
        </div>

        <div v-if="loggedInUser.token" class="details">
          <div className="flex justify-end w-full">
            <p
              @click="deleteAccountModal = true"
              class="text-red-500 cursor-pointer hover:underline text-sm">
              Delete Accoiunt
            </p>
          </div>
        </div>
        <!--<div class="input-photo" v-if="settings === true">
                <p class="content-text">Upload profile photo</p>
                <input type="file" id="file" ref="file" accept="image/*" name="image">
            </div>-->
      </form>
    </div>
  </div>

  <div v-if="deleteAccountModal">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white rounded-xl w-[24rem] h-fit p-4">
        <div class="flex justify-between">
          <h2>Delete account modal</h2>

          <div
            class="cursor-pointer border border-gray-500 p-2 hover:bg-gray-300">
            <svg
              @click="deleteAccountModal = false"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-6 h-6 cursor-pointer">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>

        <div className="mt-4 flex flex-col ">
          <label for="currentPassword">Current Password</label>
          <input
            placeholder="Current Password"
            name="currentPassword"
            class="w-full h-[51px] py-4 px-6 mt-3 outline-none border border-solid rounded-[8px] bg-[#F3F3F3] flex flex-row items-center justify-between"
            v-model="pw"
            label="Current Password"
            type="text" />
        </div>

        <div class="my-10 text-small bg-red-100 rounded-md p-2 w-full">
          <p><b>Note:</b> This action is permanent and irreversible.</p>
        </div>

        <div className="my-4 flex justify-end w-full">
          <button
            @click="handleDeleteAccount"
            class="bg-primary rounded-full px-4 py-2">
            Delete Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.border {
  border-radius: 24px;
  border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
}

.info-details {
  width: 100%;
  padding: 38px 47px;
  display: flex;
  flex-direction: column;
  gap: 47px;
  .title-div {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .error {
        font-size: 11px;
        color: red;
      }
    }
    .title {
      color: var(--black-100, #0f172a);
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .edit-button {
      opacity: 0.5;
      .edit {
        padding: 10px;
        background-color: transparent;
        height: fit-content;
        width: fit-content;
        display: flex;
        gap: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        border: 1px solid var(--black-50, rgba(15, 23, 42, 1));
        span {
          color: var(--black-50, rgba(15, 23, 42, 1));
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
    .edit-button:hover {
      opacity: 1;
    }
  }
  .content-div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .details {
      width: fit-content;
      display: flex;
      gap: 108px;
      width: 100%;
      .container {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .content-text {
          color: var(--black-50, rgba(15, 23, 42, 0.5));
          width: fit-content;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
        input {
          padding: 16px;
          width: fit-content;
          color: var(--black-100, #0f172a);
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: 0;
        }
        input.border {
          border-radius: 24px;
          border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
        }
      }
    }
    .input-photo {
      width: 100%;
      background-color: transparent;
      height: fit-content;
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: flex-start;
      .content-text {
        color: var(--black-50, rgba(15, 23, 42, 0.5));
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
      input {
        padding: 16px;
        width: 100%;
        color: var(--black-100, #0f172a);
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 30px;
        border: 1px solid var(--black-50, rgba(15, 23, 42, 1));
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .info-details {
    padding: 0px;
    border: none;
    .input {
      gap: 5px;
      .title {
        font-size: 1.25rem;
      }
    }
    button {
      span {
        font-size: 1.125rem;
      }
    }
    .content-div {
      gap: 2.09rem;
      .details {
        flex-direction: column;
        gap: 2.09rem;
        .container {
          width: 100%;
          .content-text {
            font-size: 0.875rem;
          }
          input {
            font-size: 1rem;
            border: none;
          }
        }
      }
    }
  }
}
</style>
