import Policy from "@/views/Policy.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Blog from "../views/BlogPage.vue";
import CartPage from "../views/CartPage.vue";
import Categories from "../views/Categories.vue";
import CategoryDetails from "../views/CategoryDetails.vue";
import CheckOutPage from "../views/CheckOutPage.vue";
import DefaultLayout from "../views/DefaultLayoutMenuSidebar.vue";
import FavoritePage from "../views/FavoritePage.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import HomeView from "../views/HomeView.vue";
import PageNotFound from "../views/PageNotFound.vue";
import PasswordReset from "../views/PasswordReset.vue";
import ProductCategory from "../views/ProductCategory.vue";
import ProductPage from "../views/ProductPage.vue";
import Products from "../views/Products.vue";
import Search from "../views/SearchPage.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import SignUpVerified from "../views/SignUpVerified.vue";
import Trending from "../views/Trending.vue";
import LatestArrival from "../views/LatestArrival.vue";
import Blogpost from "../views/Blogpost.vue";
import NewCategoryDetails from "../views/NewCategoryDetails.vue";
import SuccessPage from "../views/SuccessPage.vue";
// import Color from '@/views/Color.vue'
// import Size from '@/views/Size.vue'
// import Styles from '@/views/Style.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/section/:section",
    name: "Section",
    component: ProductCategory,
  },
  {
    path: "/products/:slug",
    name: "ProductPage",
    component: ProductPage,
    props: true,
  },
  {
    path: "/product",
    name: "Products",
    component: Products,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: DefaultLayout,
  },
  {
    path: "/orders",
    name: "Orders",
    component: DefaultLayout,
  },
  {
    path: "/orders/ongoing",
    name: "OrdersOngoing",
    component: DefaultLayout,
  },
  {
    path: "/orders/order-history",
    name: "OrderHistory",
    component: DefaultLayout,
  },
  {
    path: "/orders/drafts",
    name: "OrderDrafts",
    component: DefaultLayout,
  },
  {
    path: "/help-center",
    name: "HelpCenter",
    component: DefaultLayout,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: DefaultLayout,
  },
  {
    path: "/settings",
    name: "Settings",
    component: DefaultLayout,
  },
  {
    path: "/favourites",
    name: "FavoritePage",
    component: FavoritePage,
  },
  {
    path: "/cart",
    name: "CartPage",
    component: CartPage,
  },
  {
    path: "/checkout",
    name: "CheckoutPage",
    component: CheckOutPage,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/:id",
    name: "Single Blog Post",
    component: Blogpost,
  },
  {
    path: "/trending",
    name: "Trending",
    component: Trending,
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
  },
  // {
  //   path: "/categories/:category",
  //   name: "Category",
  //   component: NewCategoryDetails,
  // },
  {
    path: "/categories/:category",
    name: "Category",
    component: CategoryDetails,
  },
  // {
  //   path: "/categories/gadgets",
  //   name: "Gadgets",
  //   component: Gadgets,
  // },
  // {
  //   path: "/categories/accessories",
  //   name: "Accessories",
  //   component: Accessories,
  // },
  // {
  //   path: "/categories/clothes/men",
  //   name: "CatClotheseM",
  //   component: CatClothesM,
  // },
  // {
  //   path: "/categories/clothes/women",
  //   name: "CatClotheseW",
  //   component: CatClothesW,
  // },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/password-reset/:uid/:token",
    name: "PasswordReset",
    component: PasswordReset,
  },
  // {
  //   path: "/emailverified",
  //   name: "EmailVerify",
  //   component: SignUpVerified,
  // },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  //  {
  //    path: '/search',
  //    name: 'Search',
  //    component: () => import(/* webpackChunkName: "about" */ '../views/SearchPage.vue')
  //  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckOutPage,
  },
  {
    path: "/emailverified/:uid/:token",
    name: "verifyEmail",
    component: SignUpVerified,
  },
  {
    path: "/policy/:policy",
    name: "policy",
    component: Policy,
  },
  {
    path: "/latest-arrival",
    name: "Recently Viewed",
    component: LatestArrival,
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/payment-success-page",
    name: "Success Page",
    component: SuccessPage,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
//component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
