<template>
  <div class="min-h-screen relative pt-[200px]">
    <div class="mx-auto w-full max-w-[274px] text-center sm:max-w-[555px]">
      <div
        class="mx-auto mb-10 w-full max-w-[100px] text-center sm:max-w-[160px]">
        <img src="../assets/success.png" alt="success" />
      </div>
      <h1 class="mb-2 font-bold text-gray-800 text-2xl xl:text-4xl">
        PAYMENT SUCCESSFUL !
      </h1>
      <p class="mt-6 mb-6 text-base text-gray-700 sm:text-lg">
        Awesome! your order has been created successfully, and will delivery
        initiated right away..
      </p>

      <div v-if="orderId" class="w-3/4 my-10 mt-20 flex flex-col justify-start items-start">
        <h1 class="mb-2 font-bold text-gray-800 text-2xl xl:text-4xl">
          Order Summary
        </h1>

        <div v-if="loading">
          <Spinner />
        </div>

        <div v-else>
          <OrdersList
            v-for="(ord, n) in order"
            :key="n"
            :product_name="ord?.item?.name"
            :image="ord?.item?.image"
            :price="ord?.item?.new_price ?? ord?.item?.price"
            :size="ord?.item?.size"
            :ord_number="ord?.quantity"
            :can_delete="false"
            :is_history="false" />
        </div>
      </div>

      <a
        href="/"
        class="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-white px-5 py-3.5 text-sm font-medium text-gray-700 shadow-theme-xs hover:bg-gray-50 hover:text-gray-800">
        Continue Shopping
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useProductStore } from "@/store/product";
import OrdersList from "@/components/MenuSidebar/OrdersComponent.vue";
import Spinner from '@/components/Spinner.vue';

const route = useRoute();
const productStore = useProductStore();

const order = ref();
const orderId = ref<string | null>(null);
const loading = ref(false);

console.log(order);

onMounted(async () => {
  orderId.value = route.query.order_id as string;

  loading.value = true;
  if (orderId.value) {
    try {
      const orderDetails = await productStore.fetchOrderDetails({
        orderId: orderId.value,
      });

      order.value = orderDetails;
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }
});
</script>

