<template>
  <section>
    <!-- intro -->
    <div class="intro mb-4">
      <nav class="second-header-nav flex w-full justify-between">
        <div class="header-nav-main-text-container">
          <span class="main-text font-class">{{
            viewedCategory ? viewedCategory.name : "-"
          }}</span>
        </div>
        <div class="header-nav-pages-link">
          <div class="home hover:text-blue-400">
            <router-link class="router-link" to="/">
              <span class="home-link-text font-class">Home</span>
            </router-link>
          </div>
          <div class="arrow-container">
            <box-icon name="chevron-right"></box-icon>
          </div>
          <div class="product-gender-container hover:text-blue-400">
            <router-link class="router-link" to="/categories">
              <span class="font-class">Categories</span>
            </router-link>
          </div>
          <div class="arrow-container">
            <box-icon name="chevron-right"></box-icon>
          </div>
          <div class="product-page-container hover:text-blue-400">
            <router-link
              class="router-link"
              to="/categories/{{viewedCategory && viewedCategory.name}}">
              <span class="font-class">{{
                viewedCategory ? viewedCategory.name : "-"
              }}</span>
            </router-link>
          </div>
        </div>
      </nav>

      <!-- sub category -->
      <div v-if="renderedSubCatOnTabList.length" class="flex justify-center">
        <Tabs
          :tab_list="renderedSubCatOnTabList"
          :value="activeSubCategory"
          query_name="sub_category"
          @model-value="($event) => {
            activeSubCategory = $event
            activeDependentSection = renderedSectionOnTabList[0]
          }"
          class="inline-flex min-w-[40vw] md:min-w-0 gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] w-[80%] md:w-full overflow-x-scroll scroll-div">
          <template v-slot:tab="{ tab, index: i, is_active }">
            <div
              :class="[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ]"
              class="p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max">
              <span class="flex flex-col items-center">
                <span>{{ tab?.name }}</span>
              </span>
            </div>
          </template>
        </Tabs>
      </div>

      <!-- section -->
      <div class="flex justify-center px-10">
        <Tabs
          :tab_list="renderedSectionOnTabList"
          :value="activeDependentSection"
          query_name="dep_section"
          @model-value="($event) => (activeDependentSection = $event)"
          class="inline-flex min-w-[40vw] md:min-w-0 gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] w-[80%] md:w-full overflow-x-scroll scroll-div">
          <template v-slot:tab="{ tab, index: i, is_active }">
            <div
              :class="[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ]"
              class="p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max">
              <span class="flex flex-col items-center">
                <span>{{ tab?.name }}</span>
              </span>
            </div>
          </template>
        </Tabs>
      </div>

      <!-- filter -->
      <!-- <div class="filter">
        <div>
          <button id="fil">
            <p>Filter</p>
            <img src="../assets/images/icons/filter.png" alt="" />
          </button>
          <div id="filter">
            <span
              ><p>Size</p>
              <img src="../assets/images/icons/down.png" alt=""
            /></span>
            <span
              ><p>Style</p>
              <img src="../assets/images/icons/down.png" alt=""
            /></span>
            <span
              ><p>Color</p>
              <img src="../assets/images/icons/down.png" alt=""
            /></span>
          </div>
        </div>
        <button id="clear"><p>clear all filters</p></button>
      </div> -->
    </div>

    <!-- images -->
    <div v-if="renderedSubCatOnTabList.length" class="container">
      <div id="image">
        <ItemCard
          v-for="item in product.items?.results || []"
          :key="item.id"
          :id="item.id"
          :favorite_id="item.favorite_id"
          :name="item.name"
          :price="item.price"
          :new_price="item.new_price"
          :image="item.image"
          :slug="item.slug"
          :is_favourite="item.is_favourite">
        </ItemCard>
      </div>
    </div>
    <div
      v-if="fetching"
      class="h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300">
      <span> Refreshing item list... </span>
      <Spinner class="w-8 text-gray-500" />
    </div>

    <div v-if="!product.items?.results?.length && !renderedSubCatOnTabList?.length">
      <div class="flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300 pb-20">
        <img src="../assets/images//icons/emptyList.svg" class="w-1/3" alt="No product match this category" />
        <span>
          There are no products in - {{ viewedCategory?.name }}
        </span>
      </div>
    </div>

    <div class="w-full flex justify-center pb-10">
      <Pagination
        :data="product.items!"
        @fetch-more="fetchMore"
        :loading="fetchingMore"
        class="mx-auto" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, ComputedRef } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useProductStore } from "@/store/product";
import Pagination from "@/components/Pagination.vue";
import Tabs from "@/components/Tabs.vue";
import ItemCard from "@/components/ItemCard.vue";
import Spinner from "@/components/Spinner.vue";
// import usePagination from '@/composables/usePagination';
import axios from "axios";
import { fetchCategories } from "@/utils";
import { useQuery } from "@tanstack/vue-query";

const router = useRouter();
const route = useRoute();
const product = useProductStore();
const fetching = ref(false);

function parseUrlParameters(url: string | URL) {
  const parsedURL = new URL(url);
  const subCategoryFromL = parsedURL.searchParams.get("sub_category");
  const depSection = parsedURL.searchParams.get("dep_section");

  return { subCategoryFromL, depSection };
}

const { data: categories, refetch } = useQuery({
  queryKey: ["categories"],
  queryFn: fetchCategories,
});

onMounted(async () => {
  fetching.value = true;
  if (categories?.value?.length === 0) {
    await refetch();
  }

  // running this for when user visits the page
  // reason: viewedCategory.value could have been set from a different page
  // so the watcher doesn't run
  if ((subCategory.value || [])?.length === 0) initSubCategoriesAndSections();
});

const viewedCategory = computed(() => {
  const category = route.params.category as string;
  return categories?.value?.find((cat: { name: string }) =>
    cat.name.toLocaleLowerCase().includes(category)
  );
});

const subCategory = computed(() =>
  product.getSubcategoryById(viewedCategory.value?.id || 9)
);

const sectionDependentOnSubCat = computed(() => {
  if (!viewedCategory.value?.id && !activeSubCategory.value) return [];
  return product.getSection({
    categoryId: Number(viewedCategory.value?.id),
    subCategoryId: Number(activeSubCategory.value),
  });
});

const initSubCategoriesAndSections = () => {
  Promise.all([product.fetchSubCategories(), product.fetchSections()]);
};

const activeSubCategory = ref();
const activeDependentSection = ref();

watch(
  [() => router.currentRoute.value.query, activeSubCategory],
  (qs) => {
    const [q] = qs;
    console.log({qs, q});
    if (q.dep_section || q.sub_category) return;
    initSubCategoriesAndSections();
  },
  { deep: true }
);

const renderedSubCatOnTabList: ComputedRef<{ name: string; slug: string }[]> =
  computed(() => {
    return subCategory.value?.map((subCat: { name: any; id: any }) => {
      return {
        name: subCat.name,
        slug: String(subCat.id),
      };
    });
  });

const renderedSectionOnTabList: ComputedRef<{ name: string; slug: string }[]> =
  computed(() => {
    return sectionDependentOnSubCat.value?.map((section) => {
      return {
        name: section.name,
        slug: String(section.id),
      };
    });
  });

let prevVals: [any, any, any] | null = null;

watch(
  [viewedCategory, activeSubCategory, activeDependentSection],
  async (val) => {
    const [catVal, subCatVal, sectionVal] = val;
    if (prevVals?.every((prev, index) => prev === val[index])) return;
    prevVals = val;

    if (!catVal?.id || !sectionVal) return;

    fetching.value = true;

    try {
      await product.fetchItemsInCategorySubcategoryAndSection({
        categoryId: Number(catVal?.id),
        subCategoryId: Number(subCatVal),
        sectionId: Number(sectionVal),
      });
    } finally {
      fetching.value = false;
    }
  },
  { deep: true }
);

const fetchingMore = ref(false);
const fetchMore = (link: string) => {
  fetchingMore.value = true;
  axios
    .get(link)
    .then(({ data }) => {
      product.setItems(data);
    })
    .finally(() => {
      fetchingMore.value = false;
    });
};
</script>

<style lang="scss">
section {
  // padding-top: 5rem;
  margin-top: 3rem;

  .scroll-div::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    cursor: pointer;
  }
  .scroll-div::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
  }
  .scroll-div::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
  }

  .intro {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;

    #gender {
      display: flex;
      column-gap: 10rem;

      button {
        background: transparent;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
      }

      button:hover {
        font-weight: 600;
      }

      .active p {
        font-weight: 600 !important;
        color: #4385f5;
      }
    }

    #cats {
      margin-inline: auto;
      display: flex;
      width: fit-content;
      height: fit-content;
      justify-content: center;
      align-items: center;
      border-radius: 3.875rem;
      border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
      cursor: pointer;

      span {
        display: flex;
        padding-inline: 2rem;
        padding-block: 0.6rem;
        justify-content: center;
        align-items: center;

        p {
          font-size: 16px;
        }
      }

      span:hover {
        color: white;
        border-radius: 3.3125rem;
        background: var(--Button-Default, #4385f5);
      }
    }

    .filter {
      width: 90%;
      margin-inline: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 5rem;

        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: transparent;
          column-gap: 1rem;
          cursor: pointer;
        }
      }

      #filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;

        span {
          padding-inline: 1rem;
          padding-block: 0.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 1rem;
          width: fit-content;
          height: fit-content;
          border-radius: 3.875rem;
          border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
          cursor: pointer;
        }
      }

      #clear {
        background: transparent;
        text-transform: capitalize;
        cursor: pointer;
      }

      #clear:hover {
        color: #4385f5;
      }
    }
  }

  // products
  .container {
    width: 90%;
    margin-inline: auto;
    margin-bottom: 2rem;

    #image {
      position: relative;
      display: grid;
      padding: 1rem;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      // #single {
      //   div {
      //     position: relative;
      //     // background-image: url("../assets/images/product-photos/Rectangle417arrivals.png");
      //     // background-repeat: no-repeat;
      //     // background-size: cover;
      //     border-top-left-radius: 12px;
      //     border-top-right-radius: 12px;
      //     max-width: 24rem;
      //     height: 29rem;

      //     span {
      //       position: absolute;
      //       bottom: 1rem;
      //       right: 1.5rem;
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //       width: 2.5rem;
      //       height: 2.5rem;
      //       border-radius: 100%;
      //       background: #fff;
      //       cursor: pointer;
      //     }
      //   }

      //   #text {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     margin-top: 1rem;

      //     span {
      //       text-align: start;
      //       row-gap: 0.6rem;

      //       p {
      //         font-size: 1.1rem;
      //       }

      //       #price {
      //         font-weight: 600;
      //         font-size: 1.2rem;
      //       }
      //     }

      //     button {
      //       text-transform: capitalize;
      //       color: var(--Button-Default, #4385f5);
      //       font-size: 1rem;
      //       font-weight: 500;
      //       cursor: pointer;
      //       background-color: transparent;
      //     }
      //   }
      // }
    }
  }
}

@media only screen and (max-width: 940px) {
  section {
    .container {
      #image {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  section {
    .intro {
      #cats {
        span {
          display: flex;
          padding-inline: 0.5rem;
          padding-block: 0.5rem;
          justify-content: center;
          align-items: center;

          p {
            font-size: 12px;
          }
        }
      }

      #fil {
        border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
        padding-block: 0.5rem;
        padding-inline: 0.8rem;
        border-radius: 10px;
      }
      .filter {
        #filter {
          display: none;
        }
      }
    }
    .container {
      #image {
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.bold {
  font-weight: 500;
}
.light {
  font-weight: 300;
}
.font-class {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
}
.second-header-nav {
  display: flex;
  margin-left: 80px;
  margin-right: 100px;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border: 0;
  border-bottom: 1px solid rgba(15, 23, 42, 0.1);
  padding-bottom: 0.25rem;
  .header-nav-main-text-container {
    display: flex;
    height: auto;
    span {
      font-size: 32px;
      font-weight: 500;
      line-height: 41.66px;
    }
  }
  .header-nav-pages-link {
    display: flex;
    align-items: center;
    div {
      .router-link {
        text-decoration: none;
        color: #0f172a;
        span {
          font-size: 14px;
          line-height: 18.23px;
        }
      }
    }
    .arrow-container {
      display: flex;
      border: 1px solid #0f172a;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
@media only screen and (max-width: 940px) {
  .second-header-nav {
    width: 90%;
    margin-right: 40px;
    margin-left: 40px;
    .header-nav-main-text-container {
      span {
        font-size: 20px;
      }
    }
    .header-nav-pages-link {
      display: none;
    }
  }
}
</style>
