import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/success.png'


const _hoisted_1 = { class: "min-h-screen relative pt-[200px]" }
const _hoisted_2 = { class: "mx-auto w-full max-w-[274px] text-center sm:max-w-[555px]" }
const _hoisted_3 = {
  key: 0,
  class: "w-3/4 my-10 mt-20 flex flex-col justify-start items-start"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useProductStore } from "@/store/product";
import OrdersList from "@/components/MenuSidebar/OrdersComponent.vue";
import Spinner from '@/components/Spinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SuccessPage',
  setup(__props) {

const route = useRoute();
const productStore = useProductStore();

const order = ref();
const orderId = ref<string | null>(null);
const loading = ref(false);

console.log(order);

onMounted(async () => {
  orderId.value = route.query.order_id as string;

  loading.value = true;
  if (orderId.value) {
    try {
      const orderDetails = await productStore.fetchOrderDetails({
        orderId: orderId.value,
      });

      order.value = orderDetails;
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mx-auto mb-10 w-full max-w-[100px] text-center sm:max-w-[160px]" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "success"
        })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "mb-2 font-bold text-gray-800 text-2xl xl:text-4xl" }, " PAYMENT SUCCESSFUL ! ", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mt-6 mb-6 text-base text-gray-700 sm:text-lg" }, " Awesome! your order has been created successfully, and will delivery initiated right away.. ", -1)),
      (orderId.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "mb-2 font-bold text-gray-800 text-2xl xl:text-4xl" }, " Order Summary ", -1)),
            (loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(Spinner)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.value, (ord, n) => {
                    return (_openBlock(), _createBlock(OrdersList, {
                      key: n,
                      product_name: ord?.item?.name,
                      image: ord?.item?.image,
                      price: ord?.item?.new_price ?? ord?.item?.price,
                      size: ord?.item?.size,
                      ord_number: ord?.quantity,
                      can_delete: false,
                      is_history: false
                    }, null, 8, ["product_name", "image", "price", "size", "ord_number"]))
                  }), 128))
                ]))
          ]))
        : _createCommentVNode("", true),
      _cache[4] || (_cache[4] = _createElementVNode("a", {
        href: "/",
        class: "inline-flex items-center justify-center rounded-lg border border-gray-300 bg-white px-5 py-3.5 text-sm font-medium text-gray-700 shadow-theme-xs hover:bg-gray-50 hover:text-gray-800"
      }, " Continue Shopping ", -1))
    ])
  ]))
}
}

})