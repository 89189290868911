import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = { class: "main-btn" }
const _hoisted_3 = { class: "btn-container" }
const _hoisted_4 = { class: "flex justify-center px-10" }
const _hoisted_5 = { class: "flex flex-col items-center" }
const _hoisted_6 = {
  key: 1,
  class: "h-96 bg-gray-50 flex items-center justify-center flex-col text-2xl font-medium text-gray-300"
}
const _hoisted_7 = {
  key: 2,
  class: "flex justify-center items-center h-[30vh]"
}
const _hoisted_8 = { class: "text-2xl font-medium text-gray-400" }

import { computed, onMounted, ref } from 'vue';
import OrdersList from '@/components/MenuSidebar/OrdersComponent.vue';
import Tabs from "@/components/Tabs.vue";
import { useProductStore } from "@/store/product";
import { toggleLoading } from "@/event-bus";
import { useUserStore } from '@/store/user';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'OrdersPage',
  setup(__props) {

const activeTab = ref()
const productStore = useProductStore();
const userStore = useUserStore();
const list_header = computed(()=>[
  {name:'Pending',slug:'pending', data: orders_.value.filter((order)=>order?.status === 'pending') },
  {name:'Completed',slug:'completed', data: orders_.value.filter((order)=>order?.status === 'successful') },
  {name: 'Failed', slug: 'failed', data: orders_.value.filter((order)=>order?.status === 'failed') },
])
const activeData = computed(()=>list_header.value.find((tab)=>tab.slug === activeTab.value))
const orders_ = ref<{
  status: string;
  item: {
    id: string;
    name: string;
    price: number;
    image: string;
    new_price: number;
    size: string[];
  }
  quantity: number;
}[]>([])

onMounted(async () => {
    toggleLoading(true);

    if (userStore.getUser.token){
        const orders = await productStore.fetchAllOrders();
        const detailsPromises = orders.map(order => productStore.fetchOrderDetails({ orderId: order.id }));
        const details = await Promise.all(detailsPromises);

        orders_.value = details.reduce((acc, orderDetails, i) => {
            const status = orders[i].status;
            const orderWithDetails = orderDetails.map(detail => ({ status, ...detail }));
            return [...acc, ...orderWithDetails];
        }, []);

        toggleLoading(false);
    } else {
        toggleLoading(false);
        router.push('/signin');
    }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(Tabs, {
            tab_list: list_header.value,
            value: activeTab.value,
            query_name: "mixed",
            onModelValue: _cache[0] || (_cache[0] = ($event) => (activeTab.value = $event)),
            class: "inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
          }, {
            tab: _withCtx(({ tab, index: i, is_active }) => [
              _createElementVNode("div", {
                class: _normalizeClass([[
                                is_active
                                ? 'bg-primary text-white'
                                : 'bg-transparent hover:text-primary',
                            ], "p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"])
              }, [
                _createElementVNode("span", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
                ])
              ], 2)
            ]),
            _: 1
          }, 8, ["tab_list", "value"])
        ])
      ])
    ]),
    (activeData.value?.data.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(activeData.value?.data, (order, n) => {
          return (_openBlock(), _createBlock(OrdersList, {
            key: n,
            product_name: order?.item?.name,
            image: order?.item?.image,
            price: order?.item?.new_price ?? order?.item?.price,
            size: order?.item?.size,
            order_number: order?.quantity,
            can_delete: false || activeTab.value === list_header.value[2].slug,
            is_history: false || activeTab.value === list_header.value[1].slug
          }, null, 8, ["product_name", "image", "price", "size", "order_number", "can_delete", "is_history"]))
        }), 128))
      : _createCommentVNode("", true),
    (activeData.value?.data.length === 0 && !_unref(userStore).getAuthHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("span", null, " You have to be logged in to view orders ", -1)),
          (!_unref(userStore).getUser.token)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/signin",
                class: "text-gray-500"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Sign in")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (activeData.value?.data.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, "No " + _toDisplayString(activeData.value?.name) + " orders", 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})